/* /lms/learner/dashboard.jsx */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Calendar from '../../../components/lms/calendar'; 
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';


import Layout from '../../../components/lms/layout/layout';
import Leftside from '../../../components/lms/noticeBox';
import NoticeDialog from '../../../components/lms/modal/NoticeDialog'; // NoticeDialog
import AttendanceGraph from '../../../components/lms/attendanceGraph'; 
import PDFViewer from '../../../components/lms/modal/PDFViewer'; 

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


const Dashboard = () => {

    useTitle("대시보드");

    const accessToken = useSelector(state => state.auth.accessToken);
    const navigate = useNavigate();

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [tableData, setTableData] = useState([...dataList]);

    const [noticeList, setNoticeList] = useState([]);
    const [noticeInfo, setNoticeInfo] = useState({
        title: '',
        contents: ''
    });

    const [attendanceInfo, setAttendanceInfo] = useState({
        affiliation_availableno: 0,
        join_count: 0,
        wait_count: 0,
    });

    const [ratesInfo, setRatesInfo] = useState({
        attendance_rate: 0,
        affiliation_attendance_rate: 0,
    });

    var today = new Date();
    var nowYear = today.getFullYear();
    var nowMonth = (today.getMonth() + 1).toString().padStart(2, '0');
    var nowDate = today.getDate().toString().padStart(2, '0');

    var monthLater = new Date(today.setMonth(today.getMonth() + 1));
    var nextYear = monthLater.getFullYear();
    var nextMonth = (monthLater.getMonth() + 1).toString().padStart(2, '0');
    var nextDate = monthLater.getDate().toString().padStart(2, '0');

    var start = nowYear + "-" + nowMonth + "-" + nowDate;
    var end = nextYear + "-" + nextMonth + "-" + nextDate;

    //달력 데이터
    const calendarRef = useRef(null); 
    const [scheduleData, setScheduleData] = useState([]);
    const [year, setYear] = useState(nowYear);
    const [month, setMonth] = useState(nowMonth);

    // 공지사항 리스트
    useEffect(() => {

        getNotices();
        getTuteeInfo();

    }, []);

    // 월 변경 시 수업 리스트 가져오기
    useEffect(() => {

        if(year != "" && month != ""){
            getMyClassSchedule();
        }

    }, [month]);

    // 공지사항 리스트 가져오기
    const getNotices = async () => {

        try {
            const response = await axios.get(apiUrl+'/notice/list', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        
                        dataArr.push(info);
                    }
                }

                setNoticeList(dataArr);

            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 출석 현황 가져오기
    const getTuteeInfo = async () => {

        try {
            const response = await axios.get(apiUrl+'/tutee/info/me', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    setRatesInfo({
                        ...ratesInfo,
                        attendance_rate: resData.attendance_rate,
                        affiliation_attendance_rate: resData.affiliation_attendance_rate,
                    });
                }

            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 수업 예약 리스트 가져오기
    const getMyClassSchedule = async () => {

        try {
            const response = await axios.get(apiUrl+`/tutoring/MyClassSchedule?page=&rows=&start=${start}&end=${end}&sort=startdate,asc`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data.schdules;
                // console.log(resData);
                if(resData){
                    var roundno = response.data.data.affiliation_availableno;
                    var join_count = response.data.data.join_count;
                    var absent_count = response.data.data.absent_count;
                    var wait_count = response.data.data.wait_count;
                    wait_count = roundno - join_count - absent_count;
                    setAttendanceInfo({
                        ...attendanceInfo,
                        affiliation_availableno: roundno,
                        join_count: join_count,
                        wait_count: wait_count,
                    });
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour == 12){
                            startampm = "오후";
                        }else if(starthour > 12 && starthour < 24){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }else if(starthour == 0 || starthour == 24){
                            startampm = "오전";
                            starthour = 12;
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];
                        
                        var endtxt = info.endtxt;
                        var endtxtArr = endtxt.split(":");
                        var endampm = "오전";
                        var endhour = Number(endtxtArr[0]);
                        if(endhour == 12){
                            endampm = "오후";
                        }else if(endhour > 12 && endhour < 24){
                            endhour = endhour - 12;
                            endampm = "오후";
                        }else if(endhour == 0 || endhour == 24){
                            endampm = "오전";
                            endhour = 12;
                        }
                        endhour = endhour.toString().padStart(2, '0');
                        info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];
                        info.roundtxt = info.num + "/" + roundno;

                        info.title = info.starttxtStr;
                        info.id = info.scheduleid;
                        info.start = info.datefulltxt;

                        var now = new Date();
                        var startdate = new Date(info.startdate);
                        var enddate = new Date(info.enddate);
                        
                        // info.startdate 이랑 현재시각이랑 비교해서 현재시각이 startdate 5분 안이거나 15분 지나기 전이면 join_url 활성/비활성
                        // if(now.getTime() >= startdate.getTime() - 300000 && now.getTime() <= startdate.getTime() + 900000){
                        //     info.join_url = info.join_url;
                        // }else{
                        //     info.join_url = null;
                        // }

                        // info.startdate 이랑 현재시각이랑 비교해서 현재시각이 startdate 24시간 안이거나 enddate 전이면 join_url 활성/비활성
                        if(now.getTime() >= startdate.getTime() - 86400000 && now.getTime() <= enddate.getTime()){
                            info.join_url = info.join_url;
                        }else{
                            info.join_url = null;
                        }
                        
                        dataArr.push(info);
                    }

                    setScheduleData(dataArr);
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // '강의실' 컬럼 body 템플릿
    const roadBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.join_url ? (// 입장하기 활성/비활
                    <Button type="button" onClick={() => enterClassroom(rowData.scheduleid, rowData.join_url)} label="입장하기" className="enterBtn"/>
                ) : (
                    <span className="text-muted">비활성</span>
                )}
            </>
        );
    };
    
    // 출석 처리 후 강의실 입장하기
    const enterClassroom = async (scheduleid, join_url) => {
        //console.log(join_url);
        var params = {
            attendance: "출석"
        };

        try {
            const response = await axios.put(apiUrl+`/tutoring/attendance/${scheduleid}`, params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                //window.open(join_url, "_blank");

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = join_url;
                link.target = '_blank';
                // Append the link to the body
                document.body.appendChild(link);
                // Programmatically click the link
                link.click();
                // Remove the link after clicking
                document.body.removeChild(link);

                /*
                const zoomSchemeUrl = join_url.replace('https://us02web.zoom.us/j/', 'zoommtg://zoom.us/join?confno=');

                // Fallback link for browsers
                const fallbackLink = document.createElement('a');
                fallbackLink.href = join_url;
                fallbackLink.target = '_blank';
                fallbackLink.textContent = 'Click here to join the Zoom meeting';
                fallbackLink.style.display = 'none';
                document.body.appendChild(fallbackLink);

                // Try to open the Zoom app
                window.location.href = zoomSchemeUrl;

                // Fallback to the original link after a delay
                setTimeout(() => {
                    document.body.removeChild(fallbackLink);
                    window.open(join_url, '_blank');
                }, 1000);
                */
            }

        } catch (error) {
            console.error('Error:', error);
        }
        
    };

    // '교재' 컬럼 body 템플릿
    const bookTemplate = (rowData) => {
        return (
            <button type='button' onClick={() => bookconfirm(rowData)} className='bookBtn' aria-label={`${rowData.booktitle}교재 미리보기 버튼`}>{rowData.booktitle}</button>
        );
    };

    // 교재 클릭시 나오는 안내alert
    const bookconfirm = (data) => {
        confirmDialog({
             message: (
                <div className="flex flex-column align-items-center w-full gap-3  surface-border" role="alert">
                    <i className="pi pi-exclamation-circle text-5xl text-primary-500"></i>
                    <span className='mb-3'>수업에서 제공하는 강의 자료는 수강생만 학습목적으로 이용할 수 있습니다. 저작자의 허락없이 무단 복제 및 배포 시 민/형사상 책임을 질 수 있습니다. 동의하십니까?</span>
                </div>
            ),
            header: '주의사항',
            defaultFocus: 'accept',
            acceptLabel: '확인',
            rejectLabel: '취소' ,
            accept : () => bookAccept(data.fileurl, data.originfile),
            reject
        });
    };

    const [showPDFViewer, setShowPDFViewer] = useState(false); // PDF 뷰어 모달 상태 관리
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfName, setPdfName] = useState('');

    const bookAccept = (fileurl, originfile) => {
        //확인 버튼을 눌렀을 때 
        setPdfUrl(fileurl); // 테스트pdf
        setPdfName(originfile); // 테스트pdf
        setShowPDFViewer(true); // PDF 뷰어 모달 열기
    }

    const reject = () => {
        // 취소 버튼을 눌렀을 때
    };

    const handleClosePDFViewer = () => {
        setShowPDFViewer(false); // PDF 뷰어 모달 닫기
    };

    // 공지사항 모달
    const [noticeDialogVisible, setNoticeDialogVisible] = useState(false);
    const handleNoticeClick = (notice) => { // 공지사항 클릭 이벤트
        setNoticeInfo(prevNoticeInfo => ({
            ...prevNoticeInfo,
            title: notice.title,
            contents: notice.contents
        }));
        setNoticeDialogVisible(true);
        document.body.style.overflow = 'hidden';
    };

    const handleNoticeDialogHide = () => {// 공지사항 모달 닫기 버튼 클릭 이벤트
        setNoticeDialogVisible(false);
        document.body.style.overflow = 'auto';
    };

    // 전체보기
    const showAllClass = () => {
        //setTableData(scheduleData);
        navigate("/classResiList")
    };

    // 날짜 클릭 이벤트
    const handleDateSelect = (eventInfo) => {
        //console.log(eventInfo.startStr);
        var dataArr = [];
        for(var i = 0; i < scheduleData.length; i++){
            if(scheduleData[i].start == eventInfo.startStr){
                dataArr.push(scheduleData[i]);
            }
        }
        setTableData(dataArr);
    };

    // 달력 이벤트 클릭
    const handleClick = (eventInfo) => {
        var scheduleid = eventInfo.event.id;
        var matchItem = scheduleData.find(item => item.scheduleid == scheduleid);
        var dataArr = [matchItem];
        
        setTableData(dataArr);
    };

    // 달력 년/월 변경 시
    const handleDateChange = (newYear, newMonth) => {
        setYear(newYear);
        setMonth(newMonth);
    };
    
    const [first, setFirst] = useState(0);

    // 페이지 변경 시
    const onPageChange = (event) => {
        setFirst(event.first);
    };

    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };



    return (
        <div>
            <Layout title="대시보드" pagename="대시보드">
                 <div className='flexcont learner'>
                    <div className="contain">
                        <div className="flex flex-wrap gap-5 left">
                            <div className="toparea">
                                <div style={{ flex: 1 }}>
                                    <Leftside onNoticeClick={handleNoticeClick} notices={noticeList} /> {/* 공지사항 onNoticeClick 이벤트 핸들러 전달 */}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <AttendanceGraph attendance={attendanceInfo} rates={ratesInfo} /> {/* 출석현황 그래프 */}
                                </div>
                            </div>
                                    
                            <div className="classReservation" style={{ width: '100%', padding:'20px' }}>
                                <h3 className="flex justify-content-between">
                                    <span>예정수업 <small>*수업 시작 시간은 한국시간 기준입니다.</small></span>
                                    <button type="button" className="p-button p-component p-button-danger"
                                        onClick={showAllClass}>전체보기</button>
                                </h3>
                                <div className="tableBox" style={{ overflow: 'auto', height: '100%' }}>
                                    {/* pc */}
                                    <DataTable
                                        className="listpc"
                                        value={tableData.slice(first, first + row)}
                                        rows={row}
                                        tableStyle={{ minWidth: '50rem' }}
                                        emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                                    >
                                        <Column field="datefulltxt" header="날짜" bodyStyle={{ textAlign: 'center' }}></Column>
                                        <Column field="weektxt" header="요일" bodyStyle={{ textAlign: 'center' }}></Column>
                                        <Column field="tutorname" header="강사" bodyStyle={{ textAlign: 'center' }}>
                                        </Column>
                                        <Column field="starttxtStr" header="시작시간" bodyStyle={{ textAlign: 'center' }}>
                                        </Column>
                                        <Column field="endtxtStr" header="종료시간" bodyStyle={{ textAlign: 'center' }}>
                                        </Column>
                                        <Column field="booktitle" header="교재" body={bookTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                        <Column field="roundtxt" header="차시" bodyStyle={{ textAlign: 'center' }}></Column>
                                        <Column field="lectureroom" header="강의실" body={roadBodyTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                    </DataTable>

                                    {/* mob */}
                                    <div className="listmob">
                                        <table className="table" role="table">
                                            <thead className="screen-reader-only">
                                                <tr>
                                                    <th scope="col">차시</th>
                                                    <th scope="col">수업 날짜</th>
                                                    <th scope="col">수업 시작시간</th>
                                                    <th scope="col">수업 종료시간</th>
                                                    <th scope="col">교재명</th>
                                                    <th scope="col">강사</th>
                                                    <th scope="col">강의실</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.slice(first, first + row).map((rowData, index) => (
                                                <tr key={index}>
                                                    <td className="mb-2">
                                                        <b className="order">{rowData.roundtxt}</b>
                                                    </td>
                                                    <td> 
                                                        <button type='button' onClick={bookconfirm}
                                                            className='bookBtn book mt-2 mb-2'
                                                            aria-label={`${rowData.booktitle} 교재 미리보기 버튼`}>
                                                            {rowData.booktitle}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        {`${rowData.datefulltxt}(${rowData.weektxt})`}
                                                    </td>
                                                    <td className="inline-block">
                                                        {`${rowData.starttxtStr}`} ~
                                                    </td>
                                                    <td className="inline-block">
                                                        {`${rowData.endtxtStr}`}
                                                    </td>
                                                    <td>
                                                        {`${rowData.tutorname}`}
                                                    </td>    
                                                    <td className="flex align-items-center btns">
                                                        {rowData.join_url ? (
                                                        <Button type="button" onClick={()=>
                                                            enterClassroom(rowData.scheduleid, rowData.join_url)}
                                                            label="입장하기"
                                                            className="enterBtn"
                                                            aria-label="입장하기 버튼"
                                                            />
                                                            ) : (
                                                            <span className="text-muted">비활성</span>
                                                            )}
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                  
                                    
                                    <Paginator
                                        role="navigation"
                                        aria-label="페이지 탐색"
                                        first={first}
                                        rows={row}
                                        totalRecords={totalCnt}
                                        onPageChange={onPageChange}
                                        template={template1}
                                        tabIndex="0"
                                        onKeyDown={handleKeyDown}
                                        onTouchStart={handleTouchStart}
                                        onTouchEnd={handleTouchEnd}
                                    />

                                    
                                </div>
                            </div>
                        </div>

                        <div className='contBox calendar right'>
                            <h3>이달의 수업</h3>
                            <Calendar 
                                calendarRef={calendarRef} 
                                events={scheduleData} 
                                eventClick={handleClick} 
                                handleDateSelect={handleDateSelect} 
                                onDateChange={handleDateChange} 
                                selectable={true} 
                            />
                        </div>
                    </div>
                </div>

                {/* NoticeDialog 컴포넌트를 사용 */}
                <NoticeDialog title={noticeInfo.title} contents={noticeInfo.contents} visible={noticeDialogVisible} onHide={handleNoticeDialogHide} />

            </Layout>

            {/* 교재 alert */}
            <ConfirmDialog draggable={false} style={{ width: '30vw' }} breakpoints={{ '1100px': '75vw', '960px': '80vw' }}
                className='bookDia' />
            
            {/* PDF 뷰어 모달 */}
            <PDFViewer showModal={showPDFViewer} onCloseModal={handleClosePDFViewer} pdfUrl={pdfUrl} pdfName={pdfName} />
        </div>
    );
};
export default Dashboard;